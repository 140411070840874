
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatableLeft.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TrainningInstituteModal from '@/layout/header/partials/trainning_institute/trainning_institute_drawer.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'pending-institute-list',
  components: {
    TrainningInstituteModal,
    Datatable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      data: {},
      industry: [],
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '170px',
        },
        {
          name: 'Institute No',
          key: 'sl',
          sortable: true,
          width: '120px',
        },
        {
          name: 'Short Name',
          key: 'code',
          sortable: true,
          width: '120px',
        },
        {
          name: 'Full Name',
          key: 'name',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Address',
          key: 'address',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Website',
          key: 'website',
          sortable: true,
        },
        {
          name: 'Requested by',
          key: 'requested_by',
          sortable: true,
        },
        {
          name: 'Request Date Time',
          key: 'requested_date_time',
          sortable: true,
          width: '150px',
        },
      ],
      tableData: [],
      lists: [],
      componentinstituteKey: 0,
      loading: true,
    };
  },
  async created() {
    await this.actioncheck();
    await this.getPendingInstituteInfos();
    Object.assign(this.tableData, this.lists);
    this.emitter.on('institute-modify', async () => {
      await this.getPendingInstituteInfos();
      this.componentinstituteKey += 1;
      this.tableData = this.lists;
    });
  },
  methods: {
    async getPendingInstituteInfos() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('institute/pending_list?entity_id=' + entity_id)
        .then((response) => {
          this.loading = false;
          this.lists = response.data.data;
          this.componentinstituteKey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Training Institute') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    edit(data) {
      console.log('institute list theke bolci' + data);
      this.emitter.emit('institute-edit-modal-data', data);
    },

    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institute/approve/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('institute-modify', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institute/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('institute-modify', true);
              Swal.fire('Rejected!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
